import React from 'react';
import './Instrument.css';

export default function Instrument({ data }) {
  return (
      <div>
        <h2 className="instrument-model">{data.model}</h2>
        <div className="instrument-specs">
          <img className="instrument-picture" src={data.picture.source_url} alt={data.picture.alt_text} />
          <div>
            <h4>Information</h4>
            <p className="instrument-stock">Stock left: {data.quantity}</p>
            <p className="instrument-price">Price: $ {data.price}</p>
          </div>
        </div>
      </div>
    );
}
