import React, { useState, useEffect } from 'react';
import './Instruments.css';
import axios from 'axios';

import Instrument from '../Instrument/Instrument';

export default function Instruments() {
  const [instruments, setInstruments] = useState([]);
  useEffect(() => {
    fetchInstruments();
  }, []);

  const fetchInstruments = () => {
    axios
      .get('https://wp-dreamland-tests.duckdns.org/wp-json/wp/v2/instruments')
      .then((res) => {
        console.log(res);
        setInstruments(res.data)
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <h1>Instruments</h1>
        {instruments.map((instrument) => (
          <div key={instrument.id} className='card'>
            <hr className="instrument-division-line"></hr>
            <Instrument data={instrument.acm_fields} />
          </div>
        ))}
    </div>
    );
}
