import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BrowserRouter, Link, Route, Routes } from 'react-router-dom';
import './App.css';

import Instruments from '../Instruments/Instruments';
import About from '../About/About';

function App() {
  const [pageInfo, setPageInfo] = useState([{acm_fields:{pageTitle:'fetching title',presentation:'fetching presentation',about:'fetching about'}}]);
  useEffect(() => {
    fetchPageInfo();
  }, []);

  const fetchPageInfo = () => {
    axios
      .get('https://wp-dreamland-tests.duckdns.org/wp-json/wp/v2/pageinfo')
      .then((res) => {
        console.log(res);
        setPageInfo(res.data)
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="app-container">
      <div className="header">
        <h1 className="app-title">{pageInfo[0].acm_fields.pageTitle}</h1>
        <h3 className="app-presentation">{pageInfo[0].acm_fields.presentation.replace('<p>','').replace('</p>','')}</h3>
      </div>
      <BrowserRouter>
        <nav className="nav-list">
          <ul>
            <li><Link to="/instruments">Instruments</Link></li>
            <li><Link to="/about">About</Link></li>
          </ul>
        </nav>
        <Routes>
          <Route path="/instruments" element={<Instruments />}>
          </Route>
          <Route path="/About" element={<About about={pageInfo[0].acm_fields.about} />}>
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;